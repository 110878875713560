import Ls from "./ls"
import axios from "axios";

export default {
    async check() {
        let response = await axios.get("/session_check");

        if(!response.data.authenticated) {
            this.logout().then(() => {
                window.location.href = "/";
            })
        }

        return !!response.data.authenticated;
    },
    async checkPermisos(slug) {

        let params = {slug};
        let response = await axios.get("/paginas_acciones_usuarios/check", {params});

       if (!Number(response.data.data[1])) {

            if (slug == '/inicio') {
                this.logout().then(() => {
                    window.location.href = "/";
                })
            } else {
                setTimeout(() => {
                    window.location.href = "/";
                }, 1000);
            }
        }

        return response.data.data
    },
    async getPaginasChildrens(slug) {

        let params = {slug};
        let response = await axios.get("/paginas_acciones_usuarios/childrens", {params});

        return response.data.data
    },
    async logout() {
        Ls.remove('token');
        Ls.remove('tipo_persona');
        Ls.remove('persona_id');
        Ls.remove('nombre');
        Ls.remove('apellido');
        Ls.remove('curso_id');
        Ls.remove('rol');
    },

}
