import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

let anyoInicio = Number(2022);
let anyoActual = Number(new Date().getFullYear());

let anyoFooter = anyoInicio;
if (anyoActual > anyoInicio) {
    anyoFooter = `${anyoInicio} - ${anyoActual}`
}

export default new Vuex.Store({

    namespace: true,
    state: {
        drawer: true,
        appName: "CERTIFICACIÓN",
        anyoFooter,
        arrayRowsPages: [15, 30, 50, 100],
        footerPropsTable:
            {
                'items-per-page-options': [15, 30, 50, 100, -1],
                showFirstLastPage: true,
                firstIcon: 'keyboard_double_arrow_left',
                lastIcon: 'keyboard_double_arrow_right',
                prevIcon: 'keyboard_arrow_left',
                nextIcon: 'keyboard_arrow_right',
            }
    },
    mutations: {
        toggleDrawer(state) {
            state.drawer = !state.drawer;
        }
    },
    actions: {
        TOGGLE_DRAWER({commit}) {
            commit('toggleDrawer');
        }
    },
    getters: {
        DRAWER_STATE(state) {
            return state.drawer;
        }
    }
});
