<template>
    <v-container fluid v-if="Number(params.plantilla_certificado_id)">
        <Formulario :getParams="this.params"></Formulario>
        <!--
        <v-tabs grow>
            <v-tabs-slider></v-tabs-slider>
            <v-tab :href="`#tab_datos_personales`">
                Datos Básicos
            </v-tab>

            <v-tab :href="`#tab_datos_certificados`" v-if="permisos[5999]">
                Conf. Certificado
            </v-tab>

            <v-tab-item :value="'tab_datos_personales'">
                <Formulario :getParams="this.params"></Formulario>
            </v-tab-item>

            <v-tab-item :value="'tab_datos_certificados'">
                <Certificado :getParams="this.params"></Certificado>
            </v-tab-item>

        </v-tabs>
        -->
    </v-container>
</template>

<script>
import Formulario from "./components/Formulario.vue";
//import Certificado from './components/Certificado.vue';
import Auth from "@/services/auth";

export default {
    name: 'AdministracionPlantillasCertificadosEditar',
    data() {
        return {
            loading: true,
            permisos: [],
            params: {
                plantilla_certificado_id: null,
            }
        }
    },
    components: {
        Formulario,
        //Certificado,
    },
    methods: {
        verificarToken() {
            if (typeof this.$route.query.token !== "undefined") {
                try {
                    let token = this.$route.query.token;
                    this.params.plantilla_certificado_id = window.atob(token);
                } catch (e) {
                    console.log(e);
                    this.$toast.error("El parametro para Editar no es valido.");
                    this.regresar();
                }
            } else {
                this.$toast.error("Debe enviar un parametro para Editar.");
                this.regresar();
            }
        },
        regresar() {
            this.$router.go(-1)
        }
    },
    created() {
        Auth.checkPermisos("/administracion/plantillas-certificados").then((res) => {
            this.permisos = res;
            if (!this.permisos[3]) {
                this.regresar();
            }
            // Verificar los datos del parametro
            this.verificarToken();
        });
    }
}
</script>

<style>

</style>
