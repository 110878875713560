export default {
    light: {
      primary: "#136ebf",
      secondary: "#9013FE",
      error: "#F44336",
      warning: "#FF9800",
      success: "#4CAF50",
      info: "#00aec9",
      textColor: "#333333",
      iconColor: "#37474F",
      grayBold: '#4a4a4a'
  }
};

