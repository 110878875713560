export default {
    fechaESP: (fecha) => {
        let fechaFormato = "";
        if(fecha) {
            const options = {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
            };
            fechaFormato = new Date(fecha.toString().replace(/-/g, '/')).toLocaleDateString('es-NI', options);
        }

        return fechaFormato;
    },
    fechaESPHora: (fechaHora) => {
        let fechaHoraFormato = "Pendiente";
        if(fechaHora) {
            const options = {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: true
            };
            fechaHoraFormato = new Date(fechaHora.toString().replace(/-/g, '/')).toLocaleDateString("es-NI", options);
        }

        return fechaHoraFormato;
    },
    moneda: (numero) => {
        let options = {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        };
        return Number(numero).toLocaleString('en-US', options);
    },
    numero: (numero) => {
        return Number(numero).toLocaleString('en-US');
    },
    trueSi: (valor) => {
        let respuesta = "No";
        if(valor) {
            respuesta = "Si";
        }
        return respuesta;
    },
    trueActivo: (valor) => {
        let respuesta = "Inactivo";
        if(valor) {
            respuesta = "Activo";
        }
        return respuesta;
    },
    trueEntrada: (valor) => {
        let respuesta = "Neutro";
        if(parseInt(valor) == 1) {
            respuesta = "Entrada";
        }
        else if(parseInt(valor) == 0) {
            respuesta = "Salida";
        }

        return respuesta;
    },
    trueAbierto: (valor) => {
        let respuesta = "Cerrado";
        if(valor) {
            respuesta = "Abierto";
        }
        return respuesta;
    },
    nullVacio: (valor) => {
        if(valor == null) {
            return "(Vacio)";
        }
        return valor;
    },
    nullPendiente: (valor) => {
        if(valor == null) {
            return "(Pendiente)";
        }
        return valor;
    }

}
