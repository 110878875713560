<template>
    <v-container fluid >
        <v-row no-gutters class="d-block">
            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                <v-card>
                    <!-- Botones y búsqueda zona superior -->
                    <v-card-title>
                        <v-row>
                            <v-col cols="6" sm="3" md="3" lg="3" xl="3">
                                <v-btn color="success" small v-if="permisos[2]" outlined @click="plantillasCertificadosNuevo()">
                                    <v-icon>add</v-icon>
                                    Nuevo
                                </v-btn>
                            </v-col>
                            <v-col cols="6" sm="3" md="3" lg="3" xl="3">
                                <v-btn color="info" small outlined @click="plantillasCertificadosGet()">
                                    <v-icon>refresh</v-icon>
                                    Refrescar
                                </v-btn>
                            </v-col>
                            <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="pt-0">
                                <v-text-field
                                    v-model="search"
                                    hide-details
                                    label="Buscar por descripción."
                                    clearable>
                                    <template v-slot:append>
                                        <v-btn
                                            color="secondary" small outlined
                                            @click="plantillasCertificadosBuscar()">
                                            <v-icon>search</v-icon>
                                        </v-btn>
                                    </template>
                                    ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <!-- Lista de plantillasCertificados -->
                    <v-card-text>
                        <v-data-table
                            dense
                            class="elevation-3"
                            :headers="tableHeaders"
                            :items="plantillasCertificados"
                            :loading="loading" :disabled="loading"
                            item-key="plantilla_certificado_id"
                            fixed-header
                            height="65vh"
                            :hide-default-footer="true"
                            disable-pagination
                        >
                            <template v-slot:item="{ item }">
                                <tr>
                                    <td class="text-left">{{ item.descripcion }}</td>
                                    <td class="text-left">{{ item.plantilla }}</td>
                                    <td class="text-center">                                        
                                        <!--a :href="item.plantilla" :target="_blank" rel="noopener noreferrer">
                                            <v-icon>download</v-icon>
                                        </a-->
                                        <v-btn color="info" small outlined @click="plantillasCertificadosDownload(item)" title="Descargar" v-if="permisos[1]" class="mr-2">
                                            <v-icon>download</v-icon>
                                        </v-btn>
                                        <v-btn color="info" small outlined @click="plantillasCertificadosEdit(item)" title="Editar" v-if="permisos[3]">
                                            <v-icon>edit</v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                            </template>
                            <template v-slot:no-data>
                                <v-alert dense type="warning" outlined class="text-center mt-3">
                                    No existen registros de plantillas de certificados.
                                </v-alert>
                            </template>
                        </v-data-table>
                        <Pagination :pagination="pagination" @setCambiarPagina="setCambiarPagina" v-if="pagination.total"/>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import Auth from "@/services/auth";
import Pagination from"@/pages/Components/Pagination.vue";

export default {
    name: 'PlantillasCertificadosLista',
    components: {Pagination},
    data() {
        return {
            permisos: [],
            plantillasCertificados: [],
            search: "",
            pagination: {},
            pageActual: 1,
            rowsPage: this.$store.state.arrayRowsPages[0],
            loading: false,
            tableHeaders: [
                {
                    text: 'Descripción',
                    value: 'descripcion',
                    align: 'left',
                },
                {
                    text: 'Archivo de Plantilla',
                    value: 'plantilla',
                    align: 'left',
                },
                {
                    text: 'Acciones',
                    align: 'center',
                },
            ],
        }
    },

    methods: {
        plantillasCertificadosNuevo() {
            this.$router.push({name: 'AdministracionPlantillasCertificadosNuevo'});
        },
        plantillasCertificadosBuscar() {
            this.pageActual = 1;
            this.plantillasCertificadosGet();
        },
        plantillasCertificadosGet() {
            console.log('Enlisting plantillas certificados ...');
            this.loading = true;
            let params = {busqueda: this.search, page: this.pageActual, rowsPage: this.rowsPage};
            window.axios.get('/plantillas-certificados', {params}).then(response => {
                if (response.data.success) {
                    let data = response.data.data;
                    this.plantillasCertificados = data.plantillas.data;
                    this.pagination = data.pagination;
                } else if (response.data.success === false) {
                    this.$toast.error(response.data.message);
                }
            })
                .catch(error => {
                    console.log(error)
                    this.$toast.error("No se pudo listar datos.");
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        plantillasCertificadosDownload(plantillaCertificado) {
            let params = btoa(JSON.stringify({
                plantilla_certificado_id: plantillaCertificado.plantilla_certificado_id
            }));
            let url_plantilla = `${this.baseURLApi}/plantillas-certificados/download/${params}`;
            console.log(url_plantilla);
                this.loading = true;
                window.axios.get(url_plantilla, {responseType: 'blob'}).then(response => {
                    // Create a URL for the blob
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    const filename = plantillaCertificado.plantilla.split('/').pop();
                    const cleanedFilename = filename.replace(/^\d{8}-\d{6}-/, '');
                    link.setAttribute('download', cleanedFilename);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(url);
                    this.loading = false;
                })
                .catch(error => {
                    console.log(error)
                    this.$toast.error("No se pudo obtener la Plantilla de Certificado.");
                })
                .finally(() => {
                    this.loading = false;
                })
            //}
        },
        plantillasCertificadosEdit(plantillaCertificado) {
            if (typeof plantillaCertificado == "object") {
                let token = window.btoa(plantillaCertificado.plantilla_certificado_id);
                this.$router.push({name: 'AdministracionPlantillasCertificadosEditar', query: {token}});
            } else {
                this.$toast.error("Parametro no valido.");
            }
        },
        setCambiarPagina(data) {
            this.pageActual = data.page;
            this.rowsPage = data.rowsPage;
            this.plantillasCertificadosGet();
        },
    },
    created() {
        Auth.checkPermisos(this.$route.path).then((res) => {
            this.permisos = res;
            this.plantillasCertificadosGet();
        });
    }
}
</script>

<style>

</style>
