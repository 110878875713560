<template>
    <v-row class="" v-if="pagination.total >= 1">
        <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="mt-3">
            <div class="text-left">
                <span>Total Registros: {{ pagination.total | numero }} | Mostrando del {{ pagination.from | numero }} al {{
                        pagination.to | numero
                    }}</span>
            </div>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="mt-3">
            <nav role="navigation">
                <ul class="v-pagination theme--light">
                    <li>
                        <v-btn color="secondary" small outlined>
                            <v-select
                                class='fit'
                                v-model="rowsPage"
                                :items="$store.state.arrayRowsPages"
                                @input="cambiarPagina(1)">
                                <template v-slot:prepend>
                                    <label class="mt-2">Filas</label>
                                </template>
                            </v-select>
                        </v-btn>
                    </li>
                    <!--primera--->
                    <li title="Primera Página">
                        <template v-if="pagination.current_page > 1">
                            <v-btn color="secondary" small outlined class="v-pagination__navigation"
                                   @click.prevent="cambiarPagina(1)">
                                <v-icon>keyboard_double_arrow_left</v-icon>
                            </v-btn>
                        </template>
                        <template v-else>
                            <v-btn color="secondary" small outlined
                                   class="v-pagination__navigation v-pagination__navigation--disabled">
                                <v-icon>keyboard_double_arrow_left</v-icon>
                            </v-btn>
                        </template>
                    </li>
                    <!--anterior-->
                    <li title="Página Anterior">
                        <template v-if="pagination.current_page > 1">
                            <v-btn color="secondary" small outlined class="v-pagination__navigation"
                                   @click.prevent="cambiarPagina(pagination.current_page - 1)">
                                <v-icon>keyboard_arrow_left</v-icon>
                            </v-btn>
                        </template>
                        <template v-else>
                            <v-btn color="secondary" small outlined
                                   class="v-pagination__navigation v-pagination__navigation--disabled">
                                <v-icon>keyboard_arrow_left</v-icon>
                            </v-btn>
                        </template>
                    </li>
                    <!--las paginas-->
                    <li>
                        <v-btn color="secondary" small outlined>
                            <v-select
                                class='fit'
                                v-model="currentPage"
                                :items="pagesNumber"
                                @input="cambiarPagina(currentPage)">
                            </v-select>
                        </v-btn>
                    </li>
                    <!--siguiente-->
                    <li title="Página Siguiente">
                        <template v-if="pagination.current_page < pagination.last_page">
                            <v-btn color="secondary" small outlined class="v-pagination__navigation"
                                   @click.prevent="cambiarPagina(pagination.current_page + 1)">
                                <v-icon>keyboard_arrow_right</v-icon>
                            </v-btn>
                        </template>
                        <template v-else>
                            <v-btn color="secondary" small outlined
                                   class="v-pagination__navigation v-pagination__navigation--disabled">
                                <v-icon>keyboard_arrow_right</v-icon>
                            </v-btn>
                        </template>
                    </li>
                    <li title="Última Página">
                        <template v-if="pagination.current_page < pagination.last_page">
                            <v-btn color="secondary" small outlined class="v-pagination__navigation"
                                   @click.prevent="cambiarPagina(pagination.last_page)">
                                <v-icon>keyboard_double_arrow_right</v-icon>
                            </v-btn>
                        </template>
                        <template v-else>
                            <v-btn color="secondary" small outlined
                                   class="v-pagination__navigation v-pagination__navigation--disabled">
                                <v-icon>keyboard_double_arrow_right</v-icon>
                            </v-btn>
                        </template>
                    </li>
                </ul>
            </nav>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'Pagination',
    props: {
        // Si es requerido falso, que sea Default Vacio
        pagination: {
            type: Object,
            required: true,
            "default": {
                total: 0,
                current_page: 0,
                per_page: 0,
                last_page: 0,
                from: 0,
                to: 0,
            }
        }
    },
    data() {
        return {
            offset: 3,
            rowsPage: 15,
            currentPage: 1
        };
    },
    computed: {
        //Calcula los elementos de la paginación
        pagesNumber: function () {

            let from = 1;
            let to = this.pagination.last_page;

            let pagesArray = [];
            while (from <= to) {
                pagesArray.push(from);
                from++;
            }
            return pagesArray;
        },
    },
    methods: {
        // Cambia la pagina
        cambiarPagina(page) {

            this.currentPage = page;
            let objeto = {
                page: page,
                rowsPage: this.rowsPage
            }
            this.$emit("setCambiarPagina", objeto);
        },
    },
};
</script>


<style lang="scss" scoped>
.v-pagination {
    align-items: center;
    display: inline-flex;
    list-style-type: none;
    margin: 0;
    max-width: 100%;
    width: 100%;
    justify-content: right;

    li {
        align-items: center;
        display: flex;
    }
}

.v-select.fit {
    width: min-content;
}

.v-select.fit .v-select__selection--comma {
    text-overflow: unset;
}
</style>
