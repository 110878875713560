<template>
    <v-container fluid >
        <v-row no-gutters class="d-block">
            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                <v-card>
                    <!-- Botones y búsqueda zona superior -->
                    <v-card-title>
                        <v-row>
                            <v-col cols="6" sm="3" md="3" lg="3" xl="3">
                                <v-btn color="success" small v-if="permisos[2]" outlined @click="firmasNuevo()">
                                    <v-icon>add</v-icon>
                                    Nuevo
                                </v-btn>
                            </v-col>
                            <v-col cols="6" sm="3" md="3" lg="3" xl="3">
                                <v-btn color="info" small outlined @click="firmasGet()">
                                    <v-icon>refresh</v-icon>
                                    Refrescar
                                </v-btn>
                            </v-col>
                            <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="pt-0">
                                <v-text-field
                                    v-model="search"
                                    hide-details
                                    label="Buscar por nombre del firmante."
                                    clearable>
                                    <template v-slot:append>
                                        <v-btn
                                            color="secondary" small outlined
                                            @click="firmasBuscar()">
                                            <v-icon>search</v-icon>
                                        </v-btn>
                                    </template>
                                    ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <!-- Lista de firmas -->
                    <v-card-text>
                        <v-data-table
                            dense
                            class="elevation-3"
                            :headers="tableHeaders"
                            :items="firmas"
                            :loading="loading" :disabled="loading"
                            item-key="persona_id"
                            fixed-header
                            height="65vh"
                            :hide-default-footer="true"
                            disable-pagination
                        >
                            <template v-slot:item="{ item }">
                                <tr>
                                    <td class="text-left">{{ item.nombre }}</td>
                                    <td class="text-left">{{ item.apellido }}</td>
                                    <td class="text-center">
                                        <v-btn color="info" small outlined @click="firmasEdit(item)" title="Editar" v-if="permisos[3]">
                                            <v-icon>edit</v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                            </template>
                            <template v-slot:no-data>
                                <v-alert dense type="warning" outlined class="text-center mt-3">
                                    No existen registros de firmas.
                                </v-alert>
                            </template>
                        </v-data-table>
                        <Pagination :pagination="pagination" @setCambiarPagina="setCambiarPagina" v-if="pagination.total"/>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import Auth from "@/services/auth";
import Pagination from"@/pages/Components/Pagination.vue";

export default {
    //name: 'FirmasLista',
    name: 'AdministracionFirmas',
    components: {Pagination},
    data() {
        return {
            permisos: [],
            firmas: [],
            search: "",
            pagination: {},
            pageActual: 1,
            rowsPage: this.$store.state.arrayRowsPages[0],
            loading: false,
            tableHeaders: [
                {
                    text: 'Nombres',
                    value: 'nombre',
                    align: 'left',
                },
                {
                    text: 'Apellidos',
                    value: 'apellido',
                    align: 'left',
                },
                {
                    text: 'Acciones',
                    align: 'center',
                },
            ],
        }
    },

    methods: {
        firmasNuevo() {
            this.$router.push({name: 'AdministracionFirmasNuevo'});
        },
        firmasBuscar() {
            this.pageActual = 1;
            this.firmasGet();
        },
        firmasGet() {
            console.log('Enlisting firmas ...');
            this.loading = true;
            let params = {busqueda: this.search, page: this.pageActual, rowsPage: this.rowsPage};
            window.axios.get('/personas-administrativas', {params}).then(response => {
                if (response.data.success) {
                    let data = response.data.data;
                    this.firmas = data.personasAdministrativas.data;
                    this.pagination = data.pagination;
                } else if (response.data.success === false) {
                    this.$toast.error(response.data.message);
                }
            })
                .catch(error => {
                    console.log(error)
                    this.$toast.error("No se pudo listar datos.");
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        firmasEdit(firma) {
            if (typeof firma == "object") {
                let token = window.btoa(firma.persona_id);
                this.$router.push({name: 'AdministracionFirmasEditar', query: {token}});
            } else {
                this.$toast.error("Parametro no valido.");
            }
        },
        setCambiarPagina(data) {
            this.pageActual = data.page;
            this.rowsPage = data.rowsPage;
            this.firmasGet();
        },
    },
    created() {
        Auth.checkPermisos(this.$route.path).then((res) => {
            this.permisos = res;
            this.firmasGet();
        });
    }
}
</script>

<style>

</style>
