<template>
    <v-row no-gutters class="d-block mt-3">
        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
            <v-card>
                <v-card-text>
                    <v-form ref="form" v-model="valid" enctype="multipart/form-data">
                        <v-row>
                            <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                                <!-- Campo Descripcion -->
                                <v-text-field
                                    v-model="plantillaCertificadoModel.descripcion"
                                    :label="plantillaCertificadoForm.descripcion.label"
                                    :placeholder="plantillaCertificadoForm.descripcion.placeholder"
                                    :rules="plantillaCertificadoForm.descripcion.rules"
                                    required
                                    prepend-icon="location_city"
                                />
                            </v-col>
                            <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                                <v-file-input
                                    outlined
                                    truncate-length="50"
                                    v-model='plantillaCertificadoModel.plantilla'
                                    :label="plantillaCertificadoForm.plantilla.label"
                                    :placeholder="plantillaCertificadoForm.plantilla.placeholder"
                                    :rules="plantillaCertificadoForm.plantilla.rules"
                                    accept="aplication/vnd.openxmlformats-officedocument.presentationml.presentation, .pptx"
                                    prepend-icon="approval"
                                    @change="plantillaCertificadoChange"
                                    height="90"
                                    persistent-hint
                                >
                                    <template v-slot:append>
                                        <img
                                            v-if="plantillaCertificadoPreview"
                                            :src="plantillaCertificadoPreview"
                                            style="max-height: 90px; object-fit: cover"
                                        >
                                    </template>
                                </v-file-input>
                                <v-row>
                                    <v-col cols="6">
                                        <v-switch v-show="$route.query.token"
                                                v-model="plantillaCertificadoModel.activo"
                                                :label="plantillaCertificadoForm.activo.label"
                                                color="success"
                                                class="font-weight-bold"
                                                inset
                                                hide-details
                                        ></v-switch>
                                    </v-col>
                                    <v-col cols="6"></v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-divider class="mt-1"></v-divider>
                <v-card-actions class="justify-center py-3">
                    <v-btn outlined small @click="regresar()">
                        <v-icon>arrow_back</v-icon>
                        Regresar
                    </v-btn>
                    <v-btn outlined small color="warning" @click="reset()">
                        <v-icon>restart_alt</v-icon>
                        Limpiar
                    </v-btn>
                    <v-btn color="primary" small :loading="loading" :disabled="loading" @click="plantillaCertificadoSave()">
                        <v-icon>save</v-icon>
                        Guardar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>

export default {
    name: "AdministracionplantillaCertificadoFormulario",
    props: {
        // Si es requerido falso, que sea Default Vacio
        getParams: {
            type: Object,
            required: false,
        }
    },
    data() {
        return {
            valid: true,
            loading: false,
            //catTamanyos: [],
            //catOrientaciones: [],
            plantillaCertificadoModel: {
                plantilla_certificado_id: null,
                descripcion: null,
                plantilla: [],
                activo: true
            },
            plantillaCertificadoPreview: [],
            plantillaCertificadoForm: {
                descripcion: {
                    label: 'Descripcion',
                    placeholder: 'Descripcion',
                    rules: [(v) => !!v || 'Campo requerido'],
                },
                plantilla: {
                    label: 'Plantilla',
                    placeholder: 'Seleccione',
                    hint: "Formato (.pptx)",
                },
                activo: {
                    label: 'Activo',
                },
            },
        }
    },
    computed: {},
    methods: {
        regresar() {
            this.$router.go(-1)
        },
        reset() {
            // Validar si estamos editando
            if (Number(this.plantillaCertificadoModel.plantilla_certificado_id)) {
                this.getPlantillaCertificadoEdit();
            } else {
                this.$refs.form.reset();
            }
        },
        goToEdit() {
            let token = window.btoa(this.plantillaCertificadoModel.plantilla_certificado_id);
            this.$router.push({name: 'AdministracionPlantillasCertificadosEditar', query: {token}});
        },
        getPlantillaCertificadoCreate() {
            this.loading = true;
            // Get data;
            window.axios.get('/plantillas-certificados/create', {}).then(response => {
                if (response.data.success) {
                    // Si vienen los datos, llamar al edit
                    this.getPlantillaCertificadoEdit();
                } else if (response.data.success === false) {
                    this.$toast.error(response.data.message);
                }
            })
                .catch(error => {
                    console.log(error)
                    this.$toast.error("No se pudo obtener los datos para continuar.");
                })
                .finally(() => {
                    // Do some
                    this.loading = false;
                })
        },
        getPlantillaCertificadoEdit() {
            // Validar operaciones
            if (Number(this.getParams.plantilla_certificado_id)) {
                this.loading = true;
                this.plantillaCertificadoModel.plantilla_certificado_id = this.getParams.plantilla_certificado_id;
                let params = {plantilla_certificado_id: this.plantillaCertificadoModel.plantilla_certificado_id}
                window.axios.get('/plantillas-certificados/edit', {params}).then(response => {
                    if (response.data.success) {
                        if (response.data.data.length) {
                            this.plantillaCertificadoModel = response.data.data[0];
                            this.plantillaCertificadoPreview =`${this.baseURL}/${this.plantillaCertificadoModel.plantilla}`;
                        } else {
                            this.$toast.error("No se encontraron los datos de la Plantilla.");
                        }
                    } else if (response.data.success === false) {
                        this.$toast.error(response.data.message);
                    }
                })
                    .catch(error => {
                        console.log(error)
                        this.$toast.error("No se pudo obtener la información de la Plantilla.");
                    })
                    .finally(() => {
                        // Do some
                        this.loading = false;
                    })
            }
        },
        plantillaCertificadoSave() {
            // Validar
            if (this.$refs.form.validate()) {
                this.loading = true;
                let url = (Number(this.plantillaCertificadoModel.plantilla_certificado_id)) ? '/plantillas-certificados/update' : '/plantillas-certificados/store';
                let formData = new FormData;
                formData.append("plantilla_certificado_id", this.plantillaCertificadoModel.plantilla_certificado_id);
                formData.append("descripcion", this.plantillaCertificadoModel.descripcion);
                formData.append("plantilla", this.plantillaCertificadoModel.plantilla);
                formData.append("activo", this.plantillaCertificadoModel.activo);
                window.axios.post(url, formData).then(response => {
                    if (response.data.success) {
                        this.$toast.success(response.data.message);
                        // Regresar, si es Editar
                        if (Number(this.plantillaCertificadoModel.plantilla_certificado_id)) {
                            this.getPlantillaCertificadoEdit();
                        } else {
                            // MANDAR a la interfaz de EDITAR
                            this.plantillaCertificadoModel.plantilla_certificado_id = response.data.data;
                            this.goToEdit();
                        }
                    } else if (response.data.success === false) {
                        this.$toast.error(response.data.message);
                    }
                })
                    .catch(error => {
                        console.log(error)
                        this.$toast.error("No se pudo guardar.");
                    })
                    .finally(() => {
                        // Do some
                        this.loading = false;
                    })
            } else {
                this.$toast.warning("Complete toda la información requerida.");
            }
        },
        plantillaCertificadoChange(payload) {
            let file = payload;
            if (file) {
                //this.plantillaCertificadoPreview = URL.createObjectURL(file);
                const googleViewerUrl = `https://docs.google.com/gview?url=${URL.createObjectURL(file)}&embedded=true`;
                this.plantillaCertificadoPreview = googleViewerUrl;
                //const officeViewerUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${URL.createObjectURL(file)}`;
                //this.plantillaCertificadoPreview = officeViewerUrl;                
                URL.revokeObjectURL(file); // free memory
            } else {
                this.plantillaCertificadoPreview = null
            }
        }
    },
    created() {
        this.getPlantillaCertificadoCreate();
    }
}
</script>

<style lang="scss" scoped>
.image-preview {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    padding: 20px;
}

img.preview {
    width: 200px;
    background-color: white;
    border: 1px solid #DDD;
    padding: 5px;
}

</style>
