import Vue from 'vue'
import axios from 'axios'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

// archivos locales
import App from './App.vue'
import router from './Routes'
import store from './store/index'
import vuetify from './plugins/vuetify'
import Ls from "./services/ls";
import filters from './filters/';

Vue.use(Toast, {
  position: "top-center",
  timeout: 6000,
  hideProgressBar: true,
  closeButton: "button",
  icon: true
});

// FIltros
for (let name in filters) {
  Vue.filter(name, filters[name]);
}

//const baseURL = "http://localhost/infop_certificados/back/public";
// prod:
const baseURL = "https://private.infop.hn";
// dev:
//const baseURL = "http://private.infop.hn";
const baseURLApi = `${baseURL}/api`;
const baseURLStorage = `${baseURL}/storage`;
Vue.prototype.baseURL = baseURL;
Vue.prototype.baseURLApi = baseURLApi;
Vue.prototype.baseURLStorage = baseURLStorage;

axios.defaults.baseURL = baseURLApi;
axios.interceptors.request.use(config => {
  const token = Ls.get('token');
  config.headers["Authorization"] = `Bearer ${token}`;
  return config;
});

global.axios = axios;

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  render: h => h(App), store
}).$mount('#app')
