<template>
    <v-row no-gutters class="d-block mt-3">
        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
            <v-card>
                <v-card-text>
                    <v-form ref="form" v-model="valid" enctype="multipart/form-data">
                        <v-row>
                            <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                                <v-text-field
                                    v-model="plantillaCertificadoHistoricoModel.año"
                                    :label="plantillaCertificadoHistoricoForm.año.label"
                                    :placeholder="plantillaCertificadoHistoricoForm.año.placeholder"
                                    :rules="plantillaCertificadoHistoricoForm.año.rules"
                                    required
                                    prepend-icon="location_city"
                                />
                                <!--
                                <v-text-field
                                    v-model="plantillaCertificadoHistoricoModel.unidad_operativa"
                                    :label="plantillaCertificadoHistoricoForm.unidad_operativa.label"
                                    :placeholder="plantillaCertificadoHistoricoForm.unidad_operativa.placeholder"
                                    :rules="plantillaCertificadoHistoricoForm.unidad_operativa.rules"
                                    required
                                    prepend-icon="apartment"
                                />
                                -->
                                <!-- Ahora es una lista de unidades operativas -->
                                <v-select
                                    v-model="plantillaCertificadoHistoricoModel.unidad_operativa_id"
                                    :items="unidadesOperativas"
                                    item-text="unidad_operativa"
                                    item-value="unidad_operativa_id"
                                    :label="plantillaCertificadoHistoricoForm.unidad_operativa_id.label"
                                    :placeholder="plantillaCertificadoHistoricoForm.unidad_operativa_id.placeholder"
                                    :rules="plantillaCertificadoHistoricoForm.unidad_operativa_id.rules"
                                    required
                                    prepend-icon="checklist"
                                />
                                <v-text-field
                                    v-model="plantillaCertificadoHistoricoModel.promocion"
                                    :label="plantillaCertificadoHistoricoForm.promocion.label"
                                    :placeholder="plantillaCertificadoHistoricoForm.promocion.placeholder"
                                    required
                                    prepend-icon="description"
                                    :rules="plantillaCertificadoHistoricoForm.promocion.rules"
                                />
                            </v-col>
                            <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                                <!-- Ahora es una lista de plantillas existentes -->
                                <v-select
                                    v-model="plantillaCertificadoHistoricoModel.plantilla_certificado_id"
                                    :items="plantillas"
                                    item-text="descripcion"
                                    item-value="plantilla_certificado_id"
                                    :label="plantillaCertificadoHistoricoForm.plantilla_certificado_id.label"
                                    :placeholder="plantillaCertificadoHistoricoForm.plantilla_certificado_id.placeholder"
                                    :rules="plantillaCertificadoHistoricoForm.plantilla_certificado_id.rules"
                                    required
                                    prepend-icon="checklist"
                                />
                                <!-- Ahora es una lista de firmas existentes -->
                                <v-select
                                    v-model="plantillaCertificadoHistoricoModel.persona_id_director_ejecutivo"
                                    :items="firmas"
                                    item-text="nombre_completo"
                                    item-value="persona_id"
                                    :label="plantillaCertificadoHistoricoForm.persona_id_director_ejecutivo.label"
                                    :placeholder="plantillaCertificadoHistoricoForm.persona_id_director_ejecutivo.placeholder"
                                    :rules="plantillaCertificadoHistoricoForm.persona_id_director_ejecutivo.rules"
                                    required
                                    prepend-icon="checklist"
                                />
                                <!-- Ahora es una lista de firmas existentes -->
                                <v-select
                                    v-model="plantillaCertificadoHistoricoModel.persona_id_jefe_departamento"
                                    :items="firmas"
                                    item-text="nombre_completo"
                                    item-value="persona_id"
                                    :label="plantillaCertificadoHistoricoForm.persona_id_jefe_departamento.label"
                                    :placeholder="plantillaCertificadoHistoricoForm.persona_id_jefe_departamento.placeholder"
                                    :rules="plantillaCertificadoHistoricoForm.persona_id_jefe_departamento.rules"
                                    required
                                    prepend-icon="checklist"
                                />
                                <v-row>
                                    <v-col cols="6">
                                        <v-switch v-show="$route.query.token"
                                                v-model="plantillaCertificadoHistoricoModel.activo"
                                                :label="plantillaCertificadoHistoricoForm.activo.label"
                                                color="success"
                                                class="font-weight-bold"
                                                inset
                                                hide-details
                                        ></v-switch>
                                    </v-col>
                                    <v-col cols="6"></v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-divider class="mt-1"></v-divider>
                <v-card-actions class="justify-center py-3">
                    <v-btn outlined small @click="regresar()">
                        <v-icon>arrow_back</v-icon>
                        Regresar
                    </v-btn>
                    <v-btn outlined small color="warning" @click="reset()">
                        <v-icon>restart_alt</v-icon>
                        Limpiar
                    </v-btn>
                    <v-btn color="primary" small :loading="loading" :disabled="loading" @click="plantillaCertificadoHistoricoSave()">
                        <v-icon>save</v-icon>
                        Guardar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>

</template>

<script>

export default {
    name: "AdministracionplantillaCertificadoHistoricoFormulario",
    props: {
        // Si es requerido falso, que sea Default Vacio
        getParams: {
            type: Object,
            required: false,
        }
    },
    data() {
        return {
            valid: true,
            loading: false,
            unidadesOperativas: [],
            plantillas: [],
            firmas: [],
            plantillaCertificadoHistoricoModel: {
                plantilla_certificado_historico_id: null,
                año: null,
                unidad_operativa_id: null,
                promocion: null,
                //unidad_operativa: null,
                persona_id_director_ejecutivo: null,
                persona_id_jefe_departamento: null,
                plantilla_certificado_id: null,
                activo: true,
            },
            firmaDirectorEjectivoPreview: [],
            firmaJefeDepartamentoPreview: [],
            plantillaCertificadoHistoricoForm: {
                año: {
                    label: 'Año',
                    placeholder: 'Año',
                    rules: [(v) => !!v || 'Campo requerido'],
                },
                unidad_operativa_id: {
                    label: 'Unidad Operativa',
                    placeholder: 'Unidad Operativa',
                    rules: [(v) => !!v || 'Campo requerido'],
                },
                promocion: {
                    label: 'Promoción',
                    placeholder: 'Promoción',
                    rules: [(v) => !!v || 'Campo requerido'],
                },
                plantilla_certificado_id: {
                    label: 'Plantilla de Certificado',
                    placeholder: 'Seleccione',
                    //hint: "Formato (.pdf)",
                    rules: [
                        (v) => !!v || 'Campo requerido',
                    ],
                },
                persona_id_director_ejecutivo: {
                    label: 'Director Ejecutivo',
                    placeholder: 'Seleccione',
                    //hint: "Formato (.pdf)",
                    rules: [
                        (v) => !!v || 'Campo requerido',
                    ],
                },
                persona_id_jefe_departamento: {
                    label: 'Jefe Departamento',
                    placeholder: 'Seleccione',
                    //hint: "Formato (.pdf)",
                    rules: [
                        (v) => !!v || 'Campo requerido',
                    ],
                },
                activo: {
                    label: 'Activo',
                },
            },
        }
    },
    computed: {},
    methods: {
        regresar() {
            this.$router.go(-1)
        },
        reset() {
            // Validar si estamos editando
            if (Number(this.plantillaCertificadoHistoricoModel.plantilla_certificado_historico_id)) {
                this.getPlantillaCertificadoHistoricoEdit();
            } else {
                this.$refs.form.reset();
            }
        },
        goToEdit() {
            let token = window.btoa(this.plantillaCertificadoHistoricoModel.plantilla_certificado_historico_id);
            this.$router.push({name: 'AdministracionUnidadesOperativasEditar', query: {token}});
        },
        getPlantillaCertificadoHistoricoCreate() {
            this.loading = true;
            // Get data;
            window.axios.get('/plantillas-certificados-historico/create', {}).then(response => {
                if (response.data.success) {
                    this.unidadesOperativas = response.data.unidadesOperativas;
                    this.plantillas = response.data.plantillas;
                    this.firmas = response.data.firmas;
                    this.getPlantillaCertificadoHistoricoEdit();
                } else if (response.data.success === false) {
                    this.$toast.error(response.data.message);
                }
            })
            .catch(error => {
                console.log(error)
                this.$toast.error("No se pudo obtener los datos para continuar.");
            })
            .finally(() => {
                // Do some
                this.loading = false;
            })
        },
        getPlantillaCertificadoHistoricoEdit() {
            // Validar operaciones
            if(Number(this.getParams.plantilla_certificado_historico_id)) {
                this.loading = true;
                this.plantillaCertificadoHistoricoModel.plantilla_certificado_historico_id = this.getParams.plantilla_certificado_historico_id;
                let params = {plantilla_certificado_historico_id: this.plantillaCertificadoHistoricoModel.plantilla_certificado_historico_id}
                window.axios.get('/plantillas-certificados-historico/edit', {params}).then(response => {
                    if (response.data.success) {
                        this.unidadesOperativas = response.data.unidadesOperativas
                        this.plantillas = response.data.plantillas;
                        this.firmas = response.data.firmas;
                        if (response.data.data.length) {
                            this.plantillaCertificadoHistoricoModel = response.data.data[0];
                        } else {
                            this.$toast.error("No se encontraron los datos de la Unidad Operativa.");
                        }
                    } else if (response.data.success === false) {
                        this.$toast.error(response.data.message);
                    }
                })
                .catch(error => {
                    console.log(error)
                    this.$toast.error("No se pudo obtener la información de la Unidad Operativa.");
                })
                .finally(() => {
                    // Do some
                    this.loading = false;
                })
            }
        },
        plantillaCertificadoHistoricoSave() {
            // Validar
            if (this.$refs.form.validate()) {
                this.loading = true;
                let url = (Number(this.plantillaCertificadoHistoricoModel.plantilla_certificado_historico_id)) ? '/plantillas-certificados-historico/update' : '/plantillas-certificados-historico/store';
                let formData = new FormData;
                formData.append("plantilla_certificado_historico_id", this.plantillaCertificadoHistoricoModel.plantilla_certificado_historico_id);
                formData.append("año", this.plantillaCertificadoHistoricoModel.año);
                formData.append("unidad_operativa_id", this.plantillaCertificadoHistoricoModel.unidad_operativa_id);
                formData.append("promocion", this.plantillaCertificadoHistoricoModel.promocion);                
                formData.append("plantilla_certificado_id", this.plantillaCertificadoHistoricoModel.plantilla_certificado_id);
                formData.append("persona_id_director_ejecutivo", this.plantillaCertificadoHistoricoModel.persona_id_director_ejecutivo);
                formData.append("persona_id_jefe_departamento", this.plantillaCertificadoHistoricoModel.persona_id_jefe_departamento);
                formData.append("activo", this.plantillaCertificadoHistoricoModel.activo);
                window.axios.post(url, formData).then(response => {
                    if (response.data.success) {                        
                        this.$toast.success(response.data.message);
                        // Regresar, si es Editar
                        if (Number(this.plantillaCertificadoHistoricoModel.plantilla_certificado_historico_id)) {
                            this.getPlantillaCertificadoHistoricoEdit();
                        } else {
                            // MANDAR a la interfaz de EDITAR
                            this.plantillaCertificadoHistoricoModel.plantilla_certificado_historico_id = response.data.data;
                            this.goToEdit();
                        }
                    } else if (response.data.success === false) {
                        this.$toast.error(response.data.message);
                    }
                })
                .catch(error => {
                    console.log(error)
                    this.$toast.error("No se pudo guardar.");
                })
                .finally(() => {
                    // Do some
                    this.loading = false;
                })
            } else {
                this.$toast.warning("Complete toda la información requerida.");
            }
        },
    },
    created() {
        this.getPlantillaCertificadoHistoricoCreate();
    }
}
</script>

<style lang="scss" scoped>
.image-preview {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    padding: 20px;
}

img.preview {
    width: 200px;
    background-color: white;
    border: 1px solid #DDD;
    padding: 5px;
}

</style>
