<template>
    <v-container fluid >
        <v-row no-gutters class="d-block">
            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                <v-card>
                    <v-card-title>
                        <v-row>
                            <v-col cols="6" sm="3" md="3" lg="3" xl="3">
                                <v-btn color="success" small v-if="permisos[2]" outlined @click="plantillasCertificadosHistoricoNuevo()">
                                    <v-icon>add</v-icon>
                                    Nuevo
                                </v-btn>
                            </v-col>
                            <v-col cols="6" sm="3" md="3" lg="3" xl="3">
                                <v-btn color="info" small outlined @click="plantillasCertificadosHistoricoGet()">
                                    <v-icon>refresh</v-icon>
                                    Refrescar
                                </v-btn>
                            </v-col>
                            <!--
                            <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="pt-0">
                                <v-text-field
                                    v-model="search"
                                    hide-details
                                    label="Buscar por Institución, Unidad Operativa y pulse en el botón."
                                    clearable>
                                    <template v-slot:append>
                                        <v-btn
                                            color="secondary" small outlined
                                            @click="unidadesOperativasBuscar()">
                                            <v-icon>search</v-icon>
                                        </v-btn>
                                    </template>
                                    ></v-text-field>
                            </v-col>
                            -->
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table
                            dense
                            class="elevation-3"
                            :headers="tableHeaders"
                            :items="plantillasCertificadosHistoricos"
                            :loading="loading" :disabled="loading"
                            item-key="plantillas_certificados_historico_id"
                            fixed-header
                            height="65vh"
                            :hide-default-footer="true"
                            disable-pagination
                        >
                            <template v-slot:item="{ item }">
                                <tr>
                                    <td class="text-left">{{ item.año }}</td>
                                    <td class="text-left">{{ item.unidad_operativa }}</td>
                                    <td class="text-left">{{ item.promocion }}</td>
                                    <td class="text-right">{{ item.plantilla_certificado }}</td>
                                    <td class="text-right">{{ item.director_ejecutivo }}</td>
                                    <td class="text-right">{{ item.jefe_departamento }}</td>
                                    <td class="text-center">
                                        <v-btn color="info" small outlined @click="plantillasCertificadosHistoricoEdit(item)" title="Editar" v-if="permisos[3]">
                                            <v-icon>edit</v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                            </template>
                            <template v-slot:no-data>
                                <v-alert dense type="warning" outlined class="text-center mt-3">
                                    No existen registros.
                                </v-alert>
                            </template>
                        </v-data-table>
                        <Pagination :pagination="pagination" @setCambiarPagina="setCambiarPagina" v-if="pagination.total"/>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>

import Auth from "@/services/auth";
import Pagination from"@/pages/Components/Pagination.vue";

export default {
    name: 'PlantillasCertificadosHistoricoLista',
    components: {Pagination},
    data() {
        return {
            permisos: [],
            plantillasCertificadosHistoricos: [],
            search: "",
            pagination: {},
            pageActual: 1,
            rowsPage: this.$store.state.arrayRowsPages[0],
            loading: false,
            tableHeaders: [
                {
                    text: 'Año',
                    value: 'año',
                    align: 'left',
                },
                {
                    text: 'Unidad Operativa',
                    value: 'unidad_operativa',
                    align: 'left',
                },
                {
                    text: 'Promoción',
                    value: 'promocion',
                    align: 'left',
                },
                {
                    text: 'Plantilla Usada',
                    value: 'plantilla_certificado',
                    align: 'right',
                },
                {
                    text: 'Director Ejecutivo',
                    value: 'director_ejecutivo',
                    align: 'right',
                },
                {
                    text: 'Jefe Departamento',
                    value: 'jefe_departamento',
                    align: 'right',
                },
                {
                    text: 'Acciones',
                    align: 'center',
                },
            ],
        }
    },

    methods: {
        plantillasCertificadosHistoricoNuevo() {
            this.$router.push({name: 'AdministracionPlantillasCertificadosHistoricoNuevo'});
        },
        plantillasCertificadosHistoricoBuscar() {
            this.pageActual = 1;
            this.plantillasCertificadosHistoricoGet();
        },
        plantillasCertificadosHistoricoGet() {
            this.loading = true;
            let params = {busqueda: this.search, page: this.pageActual, rowsPage: this.rowsPage};
            window.axios.get('/plantillas-certificados-historico', {params}).then(response => {
                if (response.data.success) {
                    let data = response.data.data;
                    this.plantillasCertificadosHistoricos = data.plantillasCertificadosHistoricos.data;
                    this.pagination = data.pagination;
                } else if (response.data.success === false) {
                    this.$toast.error(response.data.message);
                }
            })
                .catch(error => {
                    console.log(error)
                    this.$toast.error("No se pudo listar datos.");
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        plantillasCertificadosHistoricoEdit(plantillaCertificadoHistorico) {
            if (typeof plantillaCertificadoHistorico == "object") {
                let token = window.btoa(plantillaCertificadoHistorico.plantilla_certificado_historico_id);
                this.$router.push({name: 'AdministracionPlantillasCertificadosHistoricoEditar', query: {token}});
            } else {
                this.$toast.error("Parametro no valido.");
            }
        },
        setCambiarPagina(data) {
            this.pageActual = data.page;
            this.rowsPage = data.rowsPage;
            this.plantillasCertificadosHistoricoGet();
        },
    },
    created() {
        Auth.checkPermisos(this.$route.path).then((res) => {
            this.permisos = res;
            this.plantillasCertificadosHistoricoGet();
        });
    }
}
</script>

<style>

</style>
