<template>
    <v-row no-gutters class="d-block mt-3">
        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
            <v-card>
                <v-card-text>
                    <v-form ref="form" v-model="valid" enctype="multipart/form-data">
                        <v-row>
                            <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                                <!-- Campos Nombre y Apellido -->
                                <v-text-field
                                    v-model="firmaModel.nombre"
                                    :label="firmaForm.nombre.label"
                                    :placeholder="firmaForm.nombre.placeholder"
                                    :rules="firmaForm.nombre.rules"
                                    required
                                    prepend-icon="location_city"
                                />
                                <v-text-field
                                    v-model="firmaModel.apellido"
                                    :label="firmaForm.apellido.label"
                                    :placeholder="firmaForm.apellido.placeholder"
                                    :rules="firmaForm.apellido.rules"
                                    required
                                    prepend-icon="apartment"
                                />
                                <!--
                                <v-text-field
                                    v-model="firmaModel.modo_formacion"
                                    :label="firmaForm.modo_formacion.label"
                                    :placeholder="firmaForm.modo_formacion.placeholder"
                                    required
                                    prepend-icon="description"
                                    :rules="firmaForm.modo_formacion.rules"
                                />
                                <v-text-field
                                    v-model="firmaModel.lugar"
                                    :label="firmaForm.lugar.label"
                                    :placeholder="firmaForm.lugar.placeholder"
                                    required
                                    prepend-icon="description"
                                    :rules="firmaForm.lugar.rules"
                                />
                                -->
                                <!--
                                <v-text-field
                                    type="number"
                                    v-model="firmaModel.nota_minima_aprobar"
                                    :label="firmaForm.nota_minima_aprobar.label"
                                    :placeholder="firmaForm.nota_minima_aprobar.placeholder"
                                    :rules="firmaForm.nota_minima_aprobar.rules"
                                    required
                                    prepend-icon="pin"
                                />
                                -->
                                <!--
                                <v-row>
                                    <v-col cols="6">
                                        <#!-- Que solo sea visible desde Editar--#>
                                        <v-select
                                            v-model="firmaModel.cat_tamanyo_id"
                                            :items="catTamanyos"
                                            item-text="nombre"
                                            item-value="cat_tamanyo_id"
                                            :label="firmaForm.cat_tamanyo_id.label"
                                            :placeholder="firmaForm.cat_tamanyo_id.placeholder"
                                            :rules="firmaForm.cat_tamanyo_id.rules"
                                            required
                                            prepend-icon="checklist"
                                        />
                                    </v-col>
                                    <v-col cols="6">
                                        <v-select
                                            v-model="firmaModel.cat_orientacion_id"
                                            :items="catOrientaciones"
                                            item-text="nombre"
                                            item-value="cat_orientacion_id"
                                            :label="firmaForm.cat_orientacion_id.label"
                                            :placeholder="firmaForm.cat_orientacion_id.placeholder"
                                            :rules="firmaForm.cat_orientacion_id.rules"
                                            required
                                            prepend-icon="checklist"
                                        />
                                    </v-col>
                                </v-row>
                                -->
                            </v-col>
                            <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                                <v-file-input
                                    outlined
                                    truncate-length="50"
                                    v-model='firmaModel.firma'
                                    :label="firmaForm.firma.label"
                                    :placeholder="firmaForm.firma.placeholder"
                                    :rules="firmaForm.firma.rules"
                                    accept="image/png"
                                    prepend-icon="approval"
                                    @change="firmaPersonaChange"
                                    height="90"
                                    required
                                    persistent-hint
                                >
                                    <template v-slot:append>
                                        <img
                                            v-if="firmaPersonaPreview"
                                            :src="firmaPersonaPreview"
                                            style="max-height: 90px; object-fit: cover"
                                        >
                                    </template>
                                </v-file-input>
                                <v-row>
                                    <v-col cols="6">
                                        <v-switch v-show="$route.query.token"
                                                v-model="firmaModel.activo"
                                                :label="firmaForm.activo.label"
                                                color="success"
                                                class="font-weight-bold"
                                                inset
                                                hide-details
                                        ></v-switch>
                                    </v-col>
                                    <v-col cols="6"></v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-divider class="mt-1"></v-divider>
                <v-card-actions class="justify-center py-3">
                    <v-btn outlined small @click="regresar()">
                        <v-icon>arrow_back</v-icon>
                        Regresar
                    </v-btn>
                    <v-btn outlined small color="warning" @click="reset()">
                        <v-icon>restart_alt</v-icon>
                        Limpiar
                    </v-btn>
                    <v-btn color="primary" small :loading="loading" :disabled="loading" @click="firmaSave()">
                        <v-icon>save</v-icon>
                        Guardar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>

export default {
    name: "AdministracionFirmaFormulario",
    props: {
        // Si es requerido falso, que sea Default Vacio
        getParams: {
            type: Object,
            required: false,
        }
    },
    data() {
        return {
            valid: true,
            loading: false,
            //catTamanyos: [],
            //catOrientaciones: [],
            firmaModel: {
                persona_id: null,
                nombre: null,
                apellido: null,
                firma: [],
                activo: true
            },
            firmaPersonaPreview: [],
            //firmaJefeDepartamentoPreview: [],
            //firmaPreview: [],
            firmaForm: {
                nombre: {
                    label: 'Nombre',
                    placeholder: 'Nombre',
                    rules: [(v) => !!v || 'Campo requerido'],
                },
                apellido: {
                    label: 'Apellido',
                    placeholder: 'Apellido',
                    rules: [(v) => !!v || 'Campo requerido'],
                },
                firma: {
                    label: 'Firma',
                    placeholder: 'Seleccione',
                    hint: "Formato (.png)",
                },
                activo: {
                    label: 'Activo',
                },
            },
        }
    },
    computed: {},
    methods: {
        regresar() {
            this.$router.go(-1)
        },
        reset() {
            // Validar si estamos editando
            if (Number(this.firmaModel.persona_id)) {
                this.getFirmaEdit();
            } else {
                this.$refs.form.reset();
            }
        },
        goToEdit() {
            let token = window.btoa(this.firmaModel.persona_id);
            this.$router.push({name: 'AdministracionFirmasEditar', query: {token}});
        },
        getFirmaCreate() {
            this.loading = true;
            // Get data;
            window.axios.get('/personas-administrativas/create', {}).then(response => {
                if (response.data.success) {
                    //this.catTamanyos = response.data.data.catTamanyos;
                    //this.catOrientaciones = response.data.data.catOrientaciones;
                    // Si vienen los datos, llamar al edit
                    this.getFirmaEdit();
                } else if (response.data.success === false) {
                    this.$toast.error(response.data.message);
                }
            })
                .catch(error => {
                    console.log(error)
                    this.$toast.error("No se pudo obtener los datos para continuar.");
                })
                .finally(() => {
                    // Do some
                    this.loading = false;
                })
        },
        getFirmaEdit() {
            // Validar operaciones
            if (Number(this.getParams.persona_id)) {
                this.loading = true;
                this.firmaModel.persona_id = this.getParams.persona_id;
                let params = {persona_id: this.firmaModel.persona_id}
                window.axios.get('/personas-administrativas/edit', {params}).then(response => {
                    if (response.data.success) {
                        if (response.data.data.length) {
                            this.firmaModel = response.data.data[0];
                            this.firmaPersonaPreview =`${this.baseURL}/${this.firmaModel.firma}`;
                            //this.firmaJefeDepartamentoPreview = `${this.baseURL}/${this.firmaModel.firma_jefe_departamento}`;
                        } else {
                            this.$toast.error("No se encontraron los datos de la Persona Administrativa.");
                        }
                    } else if (response.data.success === false) {
                        this.$toast.error(response.data.message);
                    }
                })
                    .catch(error => {
                        console.log(error)
                        this.$toast.error("No se pudo obtener la información de la Persona Administrativa.");
                    })
                    .finally(() => {
                        // Do some
                        this.loading = false;
                    })
            }
        },
        firmaSave() {
            // Validar
            if (this.$refs.form.validate()) {
                this.loading = true;
                let url = (Number(this.firmaModel.persona_id)) ? '/personas-administrativas/update' : '/personas-administrativas/store';
                let formData = new FormData;
                formData.append("persona_id", this.firmaModel.persona_id);
                formData.append("nombre", this.firmaModel.nombre);
                formData.append("apellido", this.firmaModel.apellido);
                formData.append("firma", this.firmaModel.firma);
                formData.append("activo", this.firmaModel.activo);
                window.axios.post(url, formData).then(response => {
                    if (response.data.success) {
                        this.$toast.success(response.data.message);
                        // Regresar, si es Editar
                        if (Number(this.firmaModel.persona_id)) {
                            this.getFirmaEdit();
                        } else {
                            // MANDAR a la interfaz de EDITAR
                            this.firmaModel.persona_id = response.data.data;
                            this.goToEdit();
                        }
                    } else if (response.data.success === false) {
                        this.$toast.error(response.data.message);
                    }
                })
                    .catch(error => {
                        console.log(error)
                        this.$toast.error("No se pudo guardar.");
                    })
                    .finally(() => {
                        // Do some
                        this.loading = false;
                    })
            } else {
                this.$toast.warning("Complete toda la información requerida.");
            }
        },
        /*
        firmaChange(payload) {
            let file = payload;
            if (file) {
                this.firmaPreview = URL.createObjectURL(file);
                URL.revokeObjectURL(file); // free memory
            } else {
                this.firmaPreview = null
            }
        },
        */
        firmaPersonaChange(payload) {
            let file = payload;
            if (file) {
                this.firmaPersonaPreview = URL.createObjectURL(file);
                URL.revokeObjectURL(file); // free memory
            } else {
                this.firmaPersonaPreview = null
            }
        }
    },
    created() {
        this.getFirmaCreate();
    }
}
</script>

<style lang="scss" scoped>
.image-preview {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    padding: 20px;
}

img.preview {
    width: 200px;
    background-color: white;
    border: 1px solid #DDD;
    padding: 5px;
}

</style>
