import Vue from 'vue';

import Router from 'vue-router';
import AuthService from "./services/auth";
const Layout = () => import('@/components/Layout/Layout.vue');

// DE TODOS
const Error = () => import("@/pages/Error/Error.vue");
const Inicio = () => import('@/pages/Dashboard/Dashboard.vue');


/////////////////////// DEL ADMIN ////////////////////
const LoginAdmin = () => import("@/pages/Login/LoginAdmin.vue");

/////////////////////////// PARTICIPANTES
const Participantes = () => import('@/pages/Participantes/Index.vue');
/// Estudiantes
const ParticipantesEstudiantes = () => import('@/pages/Participantes/Estudiantes/Lista.vue');
const ParticipantesEstudiantesNuevo = () => import('@/pages/Participantes/Estudiantes/Nuevo.vue');
const ParticipantesEstudiantesEditar = () => import('@/pages/Participantes/Estudiantes/Editar.vue');
/// Administradores
const ParticipantesAdministradores = () => import('@/pages/Participantes/Administradores/Lista.vue');
const ParticipantesAdministradoresNuevo = () => import('@/pages/Participantes/Administradores/Nuevo.vue');
const ParticipantesAdministradoresEditar = () => import('@/pages/Participantes/Administradores/Editar.vue');
const ParticipantesAdministradoresPerfil = () => import('@/pages/Participantes/Administradores/Perfil.vue');

/////////////////////////// CURSOS
const Cursos = () => import('@/pages/Cursos/Index.vue');
const CursosAprobados = () => import('@/pages/Cursos/Aprobados.vue');
const CursosReprobados = () => import('@/pages/Cursos/Reprobados.vue');
const CursosEditar = () => import('@/pages/Cursos/Editar.vue');
const CursosNuevo = () => import('@/pages/Cursos/Nuevo.vue');
const AutenticarCertificado = () => import('@/pages/Autenticar/AutenticarCertificado.vue');


/////////////////////////// ADMINISTRACION
const Administracion = () => import('@/pages/Administracion/Index.vue');
/// Unidades Operativas
const AdministracionUnidadesOperativas = () => import('@/pages/Administracion/UnidadesOperativas/Lista.vue');
const AdministracionUnidadesOperativasNuevo = () => import('@/pages/Administracion/UnidadesOperativas/Nuevo.vue');
const AdministracionUnidadesOperativasEditar = () => import('@/pages/Administracion/UnidadesOperativas/Editar.vue');
// IMPORTACIONES
const AdministracionImportaciones = () => import('@/pages/Administracion/Importaciones/Lista.vue');
const AdministracionImportacionesNuevo = () => import('@/pages/Administracion/Importaciones/Nuevo.vue');
const AdministracionImportacionesVer = () => import('@/pages/Administracion/Importaciones/Ver.vue');


// PERSONAS ADMIMNISTRATIVAS
import AdministracionFirmas from '@/pages/Administracion/Firmas/Lista.vue';
import AdministracionFirmasNuevo from '@/pages/Administracion/Firmas/Nuevo.vue';
import AdministracionFirmasEditar from '@/pages/Administracion/Firmas/Editar.vue';
// PLANTILLAS DE CERTIFICADOS
import AdministracionPlantillasCertificados from '@/pages/Administracion/Plantillas/Lista.vue';
import AdministracionPlantillasCertificadosNuevo from '@/pages/Administracion/Plantillas/Nuevo.vue';
import AdministracionPlantillasCertificadosEditar from '@/pages/Administracion/Plantillas/Editar.vue';
// PLANTILLAS DE CERTIFICADOS HISTORICO
import AdministracionPlantillasCertificadosHistorico from '@/pages/Administracion/PlantillasHistorico/Lista.vue';
import AdministracionPlantillasCertificadosHistoricoNuevo from '@/pages/Administracion/PlantillasHistorico/Nuevo.vue';
import AdministracionPlantillasCertificadosHistoricoEditar from '@/pages/Administracion/PlantillasHistorico/Editar.vue';

// Del ESTUDIANTE
const LoginCertificados = () => import("@/pages/Login/LoginCertificados");
const LoginCalificaciones = () => import("@/pages/Login/LoginCalificaciones");
// MIS CALIFICACIONES
const MisCalificaciones = () => import('@/pages/MisCalificaciones/Index.vue');
const MisCalificacionesHistorial = () => import('@/pages/MisCalificaciones/Historial.vue');
// MIS CURSOS
const MisCursos = () => import('@/pages/MisCursos/Index.vue');
const MisCursosAprobados = () => import('@/pages/MisCursos/Aprobados.vue');

Vue.use(Router);

const routes = [
    {
        path: '/login',
        name: 'LoginCertificados',
        component: LoginCertificados
    },
    {
        path: '/login_admin',
        name: 'LoginAdmin',
        component: LoginAdmin
    },
    {
        path: '/login_calificaciones',
        name: 'LoginCalificaciones',
        component: LoginCalificaciones
    },
    {
        path: '/autenticar',
        name: 'AutenticarCertificado',
        component: AutenticarCertificado
    },
    {
        path: '/',
        redirect: '/login',
        name: 'Layout',
        component: Layout,
        children: [
            {
                path: '/inicio',
                name: 'Inicio',
                component: Inicio,
                meta: {
                    requiresAuth: true,
                },
            },
            ///// PERMISOS
            {
                path: '/participantes',
                name: 'Participantes',
                component: Participantes,
                meta: {
                    requiresAuth: true,
                    breadcrumb: [
                        {name: 'Inicio', href: 'Inicio'},
                        {name: 'Participantes'},
                    ]
                }
            },
            {
                path: '/participantes/estudiantes',
                name: 'ParticipantesEstudiantes',
                component: ParticipantesEstudiantes,
                meta: {
                    requiresAuth: true,
                    breadcrumb: [
                        {name: 'Inicio', href: 'Inicio'},
                        {name: 'Participantes', href: 'Participantes'},
                        {name: 'Estudiantes'},
                    ]
                }
            },
            {
                path: '/participantes/estudiantes/nuevo',
                name: 'ParticipantesEstudiantesNuevo',
                component: ParticipantesEstudiantesNuevo,
                meta: {
                    requiresAuth: true,
                    breadcrumb: [
                        {name: 'Inicio', href: 'Inicio'},
                        {name: 'Participantes', href: 'Participantes'},
                        {name: 'Estudiantes', href: 'ParticipantesEstudiantes'},
                        {name: 'Nuevo'}
                    ]
                }
            },
            {
                path: '/participantes/estudiantes/editar',
                name: 'ParticipantesEstudiantesEditar',
                component: ParticipantesEstudiantesEditar,
                meta: {
                    requiresAuth: true,
                    breadcrumb: [
                        {name: 'Inicio', href: 'Inicio'},
                        {name: 'Participantes', href: 'Participantes'},
                        {name: 'Estudiantes', href: 'ParticipantesEstudiantes'},
                        {name: 'Editar'}
                    ]
                }
            },
            {
                path: '/participantes/administradores',
                name: 'ParticipantesAdministradores',
                component: ParticipantesAdministradores,
                meta: {
                    requiresAuth: true,
                    breadcrumb: [
                        {name: 'Inicio', href: 'Inicio'},
                        {name: 'Participantes', href: 'Participantes'},
                        {name: 'Administradores'},
                    ]
                }
            },
            {
                path: '/participantes/administradores/nuevo',
                name: 'ParticipantesAdministradoresNuevo',
                component: ParticipantesAdministradoresNuevo,
                meta: {
                    requiresAuth: true,
                    breadcrumb: [
                        {name: 'Inicio', href: 'Inicio'},
                        {name: 'Participantes', href: 'Participantes'},
                        {name: 'Administradores', href: 'ParticipantesAdministradores'},
                        {name: 'Nuevo'}
                    ]
                }
            },
            {
                path: '/participantes/administradores/editar',
                name: 'ParticipantesAdministradoresEditar',
                component: ParticipantesAdministradoresEditar,
                meta: {
                    requiresAuth: true,
                    breadcrumb: [
                        {name: 'Inicio', href: 'Inicio'},
                        {name: 'Participantes', href: 'Participantes'},
                        {name: 'Administradores', href: 'ParticipantesAdministradores'},
                        {name: 'Editar'}
                    ]
                }
            },
            {
                path: '/mi_perfil/',
                name: 'ParticipantesAdministradoresMiPerfil',
                component: ParticipantesAdministradoresPerfil,
                meta: {
                    requiresAuth: true,
                    breadcrumb: [
                        {name: 'Inicio', href: 'Inicio'},
                        {name: 'Mi Perfil'}
                    ]
                }
            },
            ///// CURSOS
            {
                path: '/cursos',
                name: 'Cursos',
                component: Cursos,
                meta: {
                    requiresAuth: true,
                    breadcrumb: [
                        {name: 'Inicio', href: 'Inicio'},
                        {name: 'Cursos'}
                    ]
                }
            },
            {
                path: '/cursos/aprobados',
                name: 'CursosAprobados',
                component: CursosAprobados,
                meta: {
                    requiresAuth: true,
                    breadcrumb: [
                        {name: 'Inicio', href: 'Inicio'},
                        {name: 'Cursos', href: 'Cursos'},
                        {name: 'Aprobados'}
                    ]
                }
            },
            {
                path: '/cursos/reprobados',
                name: 'CursosReprobados',
                component: CursosReprobados,
                meta: {
                    requiresAuth: true,
                    breadcrumb: [
                        {name: 'Inicio', href: 'Inicio'},
                        {name: 'Cursos', href: 'Cursos'},
                        {name: 'Reprobados'}
                    ]
                }
            },
            {
                path: '/cursos/nuevo',
                name: 'CursosNuevo',
                component: CursosNuevo,
                meta: {
                    requiresAuth: true,
                    breadcrumb: [
                        {name: 'Inicio', href: 'Inicio'},
                        {name: 'Cursos', href: 'Cursos'},
                        {name: 'Nuevo'}
                    ]
                }
            },
            {
                path: '/cursos/editar',
                name: 'CursosEditar',
                component: CursosEditar,
                meta: {
                    requiresAuth: true,
                    breadcrumb: [
                        {name: 'Inicio', href: 'Inicio'},
                        {name: 'Cursos', href: 'Cursos'},
                        {name: 'Editar'}
                    ]
                }
            },

             ////////////// ADMINISTRACION
            {
                path: '/administracion',
                name: 'Administracion',
                component: Administracion,
                meta: {
                    requiresAuth: true,
                    breadcrumb: [
                        {name: 'Inicio', href: 'Inicio'},
                        {name: 'Administración'},
                    ]
                }
            },


            /// ADMINISTRACION / FIRMAS
            {
                path: '/administracion/personas-administrativas',
                name: 'AdministracionFirmas',
                component: AdministracionFirmas,
                meta: {
                    requiresAuth: true,
                    breadcrumb: [
                        {name: 'Inicio', href: 'Inicio'},
                        {name: 'Administración', href: 'Administracion'},
                        {name: 'Firmas'}
                    ]
                }
            },
            {
                path: '/administracion/personas-administrativas/nuevo',
                name: 'AdministracionFirmasNuevo',
                component: AdministracionFirmasNuevo,
                meta: {
                    requiresAuth: true,
                    breadcrumb: [
                        {name: 'Inicio', href: 'Inicio'},
                        {name: 'Administración', href: 'Administracion'},
                        {name: 'Firmas', href: 'AdministracionFirmas'},
                        {name: 'Nuevo'}
                    ]
                }
            },
            {
                path: '/administracion/personas-administrativas/editar',
                name: 'AdministracionFirmasEditar',
                component: AdministracionFirmasEditar,
                meta: {
                    requiresAuth: true,
                    breadcrumb: [
                        {name: 'Inicio', href: 'Inicio'},
                        {name: 'Administración', href: 'Administracion'},
                        {name: 'Firmas', href: 'AdministracionFirmas'},
                        {name: 'Editar'}
                    ]
                }
            },

            /// ADMINISTRACION / PLANTILLAS CERTIFICADOS
            {
                path: '/administracion/plantillas-certificados',
                name: 'AdministracionPlantillasCertificados',
                component: AdministracionPlantillasCertificados,
                meta: {
                    requiresAuth: true,
                    breadcrumb: [
                        {name: 'Inicio', href: 'Inicio'},
                        {name: 'Administración', href: 'Administracion'},
                        {name: 'Plantillas'}
                    ]
                }
            },
            {
                path: '/administracion/plantillas-certificados/nuevo',
                name: 'AdministracionPlantillasCertificadosNuevo',
                component: AdministracionPlantillasCertificadosNuevo,
                meta: {
                    requiresAuth: true,
                    breadcrumb: [
                        {name: 'Inicio', href: 'Inicio'},
                        {name: 'Administración', href: 'Administracion'},
                        {name: 'Plantillas', href: 'AdministracionPlantillasCertificados'},
                        {name: 'Nuevo'}
                    ]
                }
            },
            {
                path: '/administracion/plantillas-certificados/editar',
                name: 'AdministracionPlantillasCertificadosEditar',
                component: AdministracionPlantillasCertificadosEditar,
                meta: {
                    requiresAuth: true,
                    breadcrumb: [
                        {name: 'Inicio', href: 'Inicio'},
                        {name: 'Administración', href: 'Administracion'},
                        {name: 'Plantillas', href: 'AdministracionPlantillasCertificados'},
                        {name: 'Editar'}
                    ]
                }
            },

            /// ADMINISTRACION / PLANTILLAS CERTIFICADOS / HISTORICO
            {
                path: '/administracion/plantillas-certificados-historico',
                name: 'AdministracionPlantillasCertificadosHistorico',
                component: AdministracionPlantillasCertificadosHistorico,
                meta: {
                    requiresAuth: true,
                    breadcrumb: [
                        {name: 'Inicio', href: 'Inicio'},
                        {name: 'Administración', href: 'Administracion'},
                        {name: 'Unidades Operativas', href: 'AdministracionUnidadesOperativas'},
                        {name: 'Histórico' }
                    ]
                }
            },
            {
                path: '/administracion/plantillas-certificados-historico/nuevo',
                name: 'AdministracionPlantillasCertificadosHistoricoNuevo',
                component: AdministracionPlantillasCertificadosHistoricoNuevo,
                meta: {
                    requiresAuth: true,
                    breadcrumb: [
                        {name: 'Inicio', href: 'Inicio'},
                        {name: 'Administración', href: 'Administracion'},
                        {name: 'Unidades Operativas', href: 'AdministracionUnidadesOperativas'},
                        {name: 'Histórico', href: 'AdministracionPlantillasCertificadosHistorico'},
                        {name: 'Nuevo'},
                    ]
                }
            },
            {
                path: '/administracion/plantillas-certificados-historico/editar',
                name: 'AdministracionPlantillasCertificadosHistoricoEditar',
                component: AdministracionPlantillasCertificadosHistoricoEditar,
                meta: {
                    requiresAuth: true,
                    breadcrumb: [
                        {name: 'Inicio', href: 'Inicio'},
                        {name: 'Administración', href: 'Administracion'},
                        {name: 'Unidades Operativas', href: 'AdministracionUnidadesOperativas'},
                        {name: 'Histórico', href: 'AdministracionPlantillasCertificadosHistorico'},
                        {name: 'Editar'},
                    ]
                }
            },





            {
                path: '/administracion/unidades_operativas',
                name: 'AdministracionUnidadesOperativas',
                component: AdministracionUnidadesOperativas,
                meta: {
                    requiresAuth: true,
                    breadcrumb: [
                        {name: 'Inicio', href: 'Inicio'},
                        {name: 'Administración', href: 'Administracion'},
                        {name: 'Unidades Operativas'}
                    ]
                }
            },
            {
                path: '/administracion/unidades_operativas/nuevo',
                name: 'AdministracionUnidadesOperativasNuevo',
                component: AdministracionUnidadesOperativasNuevo,
                meta: {
                    requiresAuth: true,
                    breadcrumb: [
                        {name: 'Inicio', href: 'Inicio'},
                        {name: 'Administración', href: 'Administracion'},
                        {name: 'Unidades Operativas', href: 'AdministracionUnidadesOperativas'},
                        {name: 'Nuevo'}
                    ]
                }
            },
            {
                path: '/administracion/unidades_operativas/editar',
                name: 'AdministracionUnidadesOperativasEditar',
                component: AdministracionUnidadesOperativasEditar,
                meta: {
                    requiresAuth: true,
                    breadcrumb: [
                        {name: 'Inicio', href: 'Inicio'},
                        {name: 'Administración', href: 'Administracion'},
                        {name: 'Unidades Operativas', href: 'AdministracionUnidadesOperativas'},
                        {name: 'Editar'}
                    ]
                }
            },
            {
                path: 'administracion/importaciones',
                name: 'AdministracionImportaciones',
                component: AdministracionImportaciones,
                meta: {
                    requiresAuth: true,
                    breadcrumb: [
                        {name: 'Inicio', href: 'Inicio'},
                        {name: 'Administración', href: 'Administracion'},
                        {name: 'Importaciones'},
                    ]
                }
            },
            {
                path: '/administracion/importaciones/nuevo',
                name: 'AdministracionImportacionesNuevo',
                component: AdministracionImportacionesNuevo,
                meta: {
                    requiresAuth: true,
                    breadcrumb: [
                        {name: 'Inicio', href: 'Inicio'},
                        {name: 'Administración', href: 'Administracion'},
                        {name: 'Importaciones', href: 'AdministracionImportaciones'},
                        {name: 'Nuevo'}
                    ]
                }
            },
            {
                path: '/administracion/importaciones/ver',
                name: 'AdministracionImportacionesVer',
                component: AdministracionImportacionesVer,
                meta: {
                    requiresAuth: true,
                    breadcrumb: [
                        {name: 'Inicio', href: 'Inicio'},
                        {name: 'Administración', href: 'Administracion'},
                        {name: 'Importaciones', href: 'AdministracionImportaciones'},
                        {name: 'Ver'}
                    ]
                }
            },
            //////////////////////// INICIA DEL ESTUDIANTE /////////////////
            {
                path: '/mis_cursos',
                name: 'MisCursos',
                component: MisCursos,
                meta: {
                    requiresAuth: true,
                    breadcrumb: [
                        {name: 'Inicio', href: 'Inicio'},
                        {name: 'Mis Cursos'},
                    ]
                }
            },
            {
                path: '/mis_cursos/aprobados',
                name: 'MisCursosAprobados',
                component: MisCursosAprobados,
                meta: {
                    requiresAuth: true,
                    breadcrumb: [
                        {name: 'Inicio', href: 'Inicio'},
                        {name: 'Mis Cursos', href: 'MisCursos'},
                        {name: 'Aprobados'}
                    ]
                }
            },
            {
                path: '/mis_calificaciones',
                name: 'MisCalificaciones',
                component: MisCalificaciones,
                meta: {
                    requiresAuth: true,
                    breadcrumb: [
                        {name: 'Inicio', href: 'Inicio'},
                        {name: 'Mis Calificaciones'},
                    ]
                }
            },
            {
                path: '/mis_calificaciones/historial',
                name: 'MisCalificacionesHistorial',
                component: MisCalificacionesHistorial,
                meta: {
                    requiresAuth: true,
                    breadcrumb: [
                        {name: 'Inicio', href: 'Inicio'},
                        {name: 'Mis Calificaciones', href: 'MisCalificaciones'},
                        {name: 'Historial'}
                    ]
                }
            },
            //////////////////////// TERMINA DEL ESTUDIANTE
        ],
    },
    {
        path: '*',
        name: 'Error',
        component: Error
    }
];

const router = new Router({
    hashbang: false,
    history: true,
    mode: 'history',
    linkActiveClass: "active",
    routes,
});


const originalPush = router.push
router.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) {
        return originalPush.call(this, location, onResolve, onReject)
    }

    return originalPush.call(this, location).catch((err) => {
        if (Router.isNavigationFailure(err)) {
            return err
        }

        return Promise.reject(err)
    })
}

router.beforeEach((to, from, next) => {
    //  If the next route is requires user to be Logged IN
    if (to.matched.some(m => m.meta.requiresAuth)) {

        return AuthService.check().then(authenticated => {

            if (!authenticated && (to.name !== 'LoginAdmin')) {
                 // manda a login principal
                 //return next({name: "LoginCertificados"})
             }

            return next();
        })
    }
    return next();
});

export default router;